.instagram-embed {
  width: 100%;

  &__heading {
    color: palette(branding, teal);
    line-height: 1;
    margin-bottom: 2rem;
    padding: 0 5%;

    @include mq(900px) {
      margin: 0 auto 2rem;
      width: 50%;
      font-size: rem(45px);
    }

    &::after {
      content: "";
      display: block;
      width: 0.95em;
      height: 4px;
      background-color: palette(branding, teal);
      margin-top: 0.15em;
    }

    h3 {
      font-size: rem(48px);
      font-family: $font2;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 1;
    }

    h4 {
      font-family: $font3;
      font-style: italic;
      font-size: rem(16px);
      font-weight: 400;
      color: palette(base, font);
      margin-bottom: 0;
      margin-top: 0.5rem;
      @include tracking(10);
    }
  }


  &__iframe {
    padding: 0 5%;

    iframe {
      margin: 2em auto!important;
    }
  }
}
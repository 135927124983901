.story-prev-next {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 2rem;

    &::after {
        content: '';
        display: none;
    }

    hr {
        border-color: #dadada;
        width: 100%;
    }

    a {
        @include swash();
        font-family: $font3;
        font-style: italic;
    }

    &:last-child {
        margin-left: auto;
    }
}
.adopted {
    text-transform: capitalize!important;

    i {
        font-style: normal;
        text-transform: uppercase;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0.05em;
            height: 0.08em;
            background: #fff;
        }
    }
}
/* ol */.timeline {
    @include clearfix();
    position: relative;
    max-width: 30rem;
    // margin-top: 4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12rem;
    background: linear-gradient(palette(branding, green--l), palette(branding, green--l)) repeat-y 50% 0;
    background-size: 3px 30px;
    background-position: 1rem 0;
    padding-left: calc(1rem + 3px);

    @include mq(30em) {
        padding-left: 90px;
        background-position: 45px 0;
    }

    @include mq(60em) {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        background-position: 50% 0;
    }

    /* Cover first part of background line */
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: calc(1rem - 5px);
        width: 20px;
        height: 1rem;
        background: #FFF;
    }


    &__primary {
        @include mq(60em) {
            width: calc(50% - 65px - 2rem);
            padding-right: 1rem;
            flex: 0 0 auto;
            order: 1;
        }

        @include mq(xl) {
            padding-left: 3rem;
            padding-right: 2rem;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }


    &__secondary {
        max-width: 200px;
        margin-bottom: 1rem;

        @include mq(60em) {
            width: calc(50% - 65px - 2rem);
            max-width: none;
            flex: 0 0 auto;
            order: 3;
            margin-bottom: 0;
        }

        img {
            display: block;

            @include mq(60em) {
                max-height: 400px;
                width: auto;
                margin-left: auto;
                margin-right: auto;
            }

            &.xs {
                max-height: 140px;
                margin-left: 0;
                margin-right: auto;
            }
        }
    }


    &__event {
        z-index: 1;
        clear: both;
        display: block;
        position: relative;
        margin-bottom: 5rem;
        padding-left: 1.5rem;
        font-size: rem(15px);
        line-height: 1.8;
        color: palette(base, font--d);
        font-weight: 500;

        @include mq(30em) {
            font-size: rem(16px);
            min-height: 10rem;
            margin-bottom: 8rem;
        }

        @include mq(60em) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
        }


        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0.3rem;
            left: 0;
            width: 16px;
            height: 16px;
            margin-left: -10px;
            background: #FFF;
            border: 4px solid palette(branding, green--l);
            border-radius: 130px;

            @include mq(30em) {
                display: none;
            }
        }


        /* Cover first part of background line */
        &:first-child {

            @include mq( 960px ) {
                margin-top: -5rem;
            }

            &::after {
                @include mq(60em) {
                    z-index: -1;
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 50%;
                    width: 20px;
                    background: #FFF;
                    border: 0;
                }
            }
        }


        &:last-child {
            margin-bottom: 0;
        }


        &--rtl {
            @include mq(60em) {
                flex-direction: row-reverse;
            }

            .timeline__primary {
                @include mq(60em) {
                    padding-right: 0;
                    padding-left: 1rem;
                }

                @include mq(xl) {
                    padding-left: 2rem;
                    padding-right: 3rem;
                }
            }
        }


        &--a {
            blockquote {
                font-size: rem(15px);

                @include mq(60em) {
                    font-size: rem(18px);
                }
            }

            cite span {
                display: block;
                color: palette(base, font);
                text-transform: none;
            }

            &:first-child img {
                @include mq(60em) {
                    position: relative;
                    top: 6rem;
                }
            }
        }


        &--b {
            font-size: rem(20px);

            @include mq(30em) {
                font-size: rem(26px);
            }
        }


        &--c,
        &--d {
            blockquote {
                margin-top: 0;

                p {
                    color: palette(branding, salmon);
                    font-size: rem(30px);
                }
            }

            br {
                display: none;
            }

            @include mq(60em) {
                display: block;
                width: 100%;
                margin-top: 10rem;
                padding-top: 7rem;
                padding-bottom: 5rem;
                background: #fff;
                border-top: 8px solid palette(branding, green--l);
                border-bottom: 8px solid palette(branding, green--l);
                text-align: center;

                br {
                    display: block;
                }

                .timeline__primary {
                    width: 100%;
                    max-width: 46rem;
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 0;
                    padding-right: 0;
                }

                .timeline__date {
                    position: absolute;
                    left: calc(50% - 65px);
                    top: -68px;
                }

                .timeline__primary > p {
                    max-width: 40rem;
                    margin-left: auto;
                    margin-right: auto;
                }

                blockquote {
                    margin-top: 2rem;

                    p {
                        font-size: rem(56px);
                        line-height: 1.1;
                    }

                    cite {
                        max-width: 20em;
                        padding-bottom: 1rem;
                        margin-bottom: 2rem;
                        margin-left: auto;
                        margin-right: auto;
                        font-size: rem(16px);
                        border-bottom: 1px solid palette(ui, grey);

                        &::before {
                            display: none;
                        }
                    }
                }

                img {
                    margin-top: -2rem;
                }
            }
        }


        &--c {
            img.small {
                width: 360px;
                height: auto;
            }
        }


        &--d {
            .timeline__primary {
                max-width: none;
            }

            @include mq(60em) {
                padding-bottom: 3rem;

                blockquote cite {
                    border: none;
                }
            }
        }
    }


    &__date {
        display: inline-block;
        line-height: 1.25;
        margin-bottom: 2rem;
        border-bottom: 2px solid;
        font-family: $font2;
        font-weight: 700;
        color: palette(branding, navy);

        @include mq(30em) {
            position: absolute;
            left: -90px;
            width: 90px;
            height: 90px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: palette(branding, navy);
            border-radius: 90px;
            border: 0;
            color: #fff;
            font-size: rem(36px);
        }

        @include mq(60em) {
            left: -130px;
            width: 130px;
            height: 130px;
            border-radius: 130px;
            position: relative;
            left: 0;
            flex: 0 0 130px;
            order: 2;
            font-size: rem(46px);
        }

        & + * {
            display: block;
        }
    }


    &__hotline {
        margin-bottom: 0;
        font-family: $font2;
        color: palette(branding, salmon);
        font-weight: 700;
        font-size: 12vw;

        @include mq(40em) {
            font-size: rem(70px);
            line-height: 1.2;
        }

        @include mq(60em) {
            max-width: none!important;
            font-size: rem(160px);
        }
    }
}
.pagination {
    text-align: center;

    ul {
        display: inline;
        margin: 0;
        padding: 0;
        text-align: center;

        li {
            display: inline;
            list-style: none;
        }

        ul li {
            display: none;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                display: inline;

                a {
                    display: inline-block;
                }
            }

            &:nth-child(4)::after {
                content: "..."
            }
        }

        li a {
            border: 2px solid transparent;
            color: palette(base, font);
            outline: none;
            padding: 0.25em 0.5em;
            text-decoration: none;
            font-size: rem(13px);
            font-weight: 500;
            transition: all 0.2s ease;

            &:hover,
            &:focus {
                border-color: rgba(palette(branding, green), 0.5);
            }

            &.is-active {
                border-color: palette(branding, green);
            }
        }
    }

    &__prev,
    &__first,
    &__next,
    &__last {
        a::before,
        a::after {
            font-size: 0.75em;
            position: relative;
            top: -0.01em;
            font-weight: 600;
        }
    }

    &__prev a::before {
        content: "<";
        margin-right: 0.2em;
    }

    &__first a::before {
        content: "«";
        margin-right: 0.2em;
    }

    &__next a::after {
        content: ">";
        margin-left: 0.2em;
    }

    &__last a::after {
        content: "»";
        margin-left: 0.2em;
    }
}
.l-container .l-flush {
	margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;

    @include mq(m) {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.underline-title {
    @include swash();
    border-bottom: 2px solid palette(ui, grey);
    color: palette(branding, pri) !important;
    display: block;
    font-size: rem(36) !important;
    font-weight: 400 !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    max-width: $max-width--11;
    padding-bottom: 2rem;
    position: relative;
    text-align: center;

    &::after,
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: palette(ui, grey);
    }

    &::after {
        right: 0;
        margin-right: -2px;
    }

    &::before {
        left: 0;
        margin-left: -2px;
    }
}
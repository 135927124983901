.contact-card {
    margin: 0 auto 4rem;
    max-width: 840px;
    position: relative;

    @include mq(m) {
        display: flex;
        flex-wrap: wrap;
    }


    &__intro {
        padding: 2rem 2rem;
        background-color: #fff;
        text-align: center;

        @include mq(m) {
            bottom: 100%;
            padding: 2rem 4rem;
            position: absolute;
            width: 100%;
        }

        .lede {
            margin-top: 0;
            margin-bottom: 0;
            color: palette(branding, navy);

            em {
                color: palette(branding, green);
            }
        }
    }


    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: palette(branding, green--l);

        @include mq(m) {
            width: 50%;
        }

        img {
            max-width: 300px;
        }
    }


    &__address {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
        background-color: palette(branding, green);
        color: #fff;
        font-size: rem(16px);
        line-height: 1.75;

        @include mq(m) {
            width: 50%;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        a {
            &:link,
            &:visited {
                color: #fff;
            }
        }
    }
}
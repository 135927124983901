/* section */.i-am-gladney {
    position: relative;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: palette(branding, navy);
    text-align: center;
    color: #fff;


    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 10px;
        background-image: url('/static/img/multi-border.png');
        background-repeat: repeat-y;
        background-size: 100%;
        background-position: 50% top;
    }


    &::after {
        top: auto;
        bottom: 0;
        transform: scaleX(-1);
    }


    .l-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq(xl) {
            flex-direction: row;
            justify-content: space-between;
        }
    }


    &__top {
        margin-bottom: 3rem;

        @include mq(900px) {
           display: flex;
           align-items: center;
           justify-content: center;
        }

        @include mq(xl) {
            justify-content: flex-start;
            flex: 1 1 auto;
            width: 60%;
            margin-bottom: 1rem;
        }
    }


    img.i-am-gladney__graphic {
        display: inline-block;
        width: 185px;
        max-width: 80%;
        margin-bottom: 2rem;
        // flex: 0 1 22%;

        @include mq(900px) {
            margin-bottom: 0;
        }
    }


    &__title {
        margin-bottom: 0;
        font-family: $font1;
        font-size: rem(13px);
        line-height: 1.5;
        font-weight: 70;
        @include tracking(200);
        text-transform: uppercase;
        color: rgba(#fff, 0.8);

        @include mq(m) {
            font-size: rem(16px);
            color: #fff;
        }

        @include mq(900px) {
            margin-left: 2rem;
        }

        strong {
            display: block;
            margin-top: 0.3em;
            margin-bottom: 0.3em;
            font-family: $font2;
            font-size: rem(47px);
            line-height: 1;
            @include tracking(25);
            color: #fff;
        }

        br {
            display: none;

            @include mq(440px) {
                display: block;
            }
        }
    }


    &__text {
        font-size: rem(14px);
        line-height: 2.14;
        max-width: 35em;

        @include mq(900px) {
            max-width: 50em;
        }

        @include mq(xl) {
            flex: 1 1 auto;
            width: 40%;
            max-width: none;
        }

        .button {
            margin-top: 1em;
            font-size: rem(16px);
            background-color: palette(branding, salmon--d);
        }
    }
}
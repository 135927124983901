/* div */.profile-snippet {
    width: 100%;
    max-width: 20rem;
    background-color: palette(ui, grey);

    &__photo {
        display: block;

        img {
            width: 100%;
            transition: opacity 0.3s ease;
        }

        &:focus,
        &:hover {
            img {
                opacity: 0.8;
            }
        }
    }


    &__details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0.5rem;

        h4 {
            margin: 0 auto 0 0;
            font-family: $font2;
            font-size: rem(24px);
            text-transform: uppercase;
            color: palette(base, font--l);
        }
    }


    &__link {
        display: block;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        background-color: none;
        border-top: 1px solid palette(ui, grey--l);
        text-align: center;
        font-size: rem(12px);
        font-weight: 500;
        text-transform: uppercase;
        @include tracking(75);
        color: palette(base, font--l);

        &:focus,
        &:hover {
            background-color: palette(branding, navy);
            color: #fff;
        }
    }


    .meta-list--inline {
        margin-top: 0;
        margin-bottom: 0;

        &,
        dt,
        dd {
            font-size: rem(13px);
            line-height: 1.75;
        }
    }
}
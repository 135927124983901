.cta-bucket {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 4rem;

    .l-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        // flex-wrap: wrap;
        // width: calc(100vw - 40px);
        padding-left: 10px;
        padding-right: 10px;

        @include mq( 700px ) {
            flex-direction: row;
        }

        &::after {
            display: none;
        }
    }

    p {
        flex: 1 1 60%;
        font-size: rem(16px);
        text-align: center;

        @include mq(700px) {
            margin-bottom: 0;
            margin-right: $col-width-pc;
            text-align: left;
        }
    }

    .button.button {
        border-width: 3px;
        white-space: normal;
        line-height: 1;
        padding: 0.5em;
        flex: 0 1 33.3333%;
        align-self: center;
        letter-spacing: 0;
        margin: 10px;
    }

    &--shadow {
        background-color: #f9f9f9;
        background-image: url('/static/img/bg-cta-bucket.png');
        background-repeat: repeat-x;
        background-size: auto 100%;
        margin-top: 4rem;
    }


    &--image {
        align-items: center;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        min-height: 26vw;
        padding-bottom: 3rem;
        padding-top: 3rem;

        &::after {
            display: none;
        }

        .l-container {
            width: 100%;
        }

        .button.button {
            color: #fff !important;
            flex: 0 1 auto;
            font-size: 1.8888rem;
            padding: 0.8em 1.5em;
            text-shadow: 0 0 18px rgba(0,0,0, 0.5);

            &:focus,
            &:hover {
                color: palette(branding, salmon) !important;
            }
        }
    }


    &.has-text {

        .l-container {

            @include mq(700px) {
                justify-content: space-between;
            }
        }
    }
}
.story {
    @include mq(760px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }


    &__image {
        display: block;
        max-width: 250px;
        position: relative;
        text-align: center;
        font-size: rem(30px);
        line-height: 1;
        font-family: $font2;
        text-transform: uppercase;
        @include tracking(10);
        margin-bottom: 2rem;
        color: #fff;

        @include mq(760px) {
            width: 30%;
        }

        @include mq(900px) {
            width: 32%;
            max-width: none;
        }

        img {
          display: block;
          border-radius: 100%;
          width: 100%;
          height: auto;
        }

        span {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 8rem;
            height: 8rem;
            position: absolute;
            right: -1rem;
            bottom: -0.75rem;
            padding: 1rem;
            border-radius: 100%;
            background-color: palette(branding, salmon);
        }
    }

    a.story__image {
        &:focus,
        &:hover {
            img {
                opacity: 0.8;
                transition: opacity 0.3s ease;
            }

            span {
                color: #fff;
                background-color: palette(branding, green);
                transition: background-color 0.3s ease;
            }
        }
    }


    &__content {
        font-size: rem(20px);

        @include mq(760px) {
            width: 80%;
            padding-left: 4rem;
        }

        @include mq(900px) {
            width: 60%;
            padding-left: 4rem;
        }

        p {
            font-weight: 500;
        }
    }


    &__heading {
        color: palette(branding, teal);
        line-height: 1;
        margin-bottom: 2rem;

        @include mq(900px) {
            margin-left: -3rem;
            font-size: rem(45px);
        }

        &::after {
            content: "";
            display: block;
            width: 0.95em;
            height: 4px;
            background-color: palette(branding, teal);
            margin-top: 0.15em;
        }


        h3 {
            font-size: rem(48px);
            font-family: $font2;
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1;
        }


        h4 {
            font-family: $font3;
            font-style: italic;
            font-size: rem(16px);
            font-weight: 400;
            color: palette(base, font);
            margin-bottom: 0;
            margin-top: 0.5rem;
            @include tracking(10);
        }
    }


    &--staff {
        .story__image {
            span {
                width: 10rem;
                height: 10rem;
                background-color: palette(branding, green);

                em {
                    display: block;
                    margin-top: 0.75em;
                    font-family: $font1;
                    font-style: normal;
                    font-size: rem(13px);
                    line-height: 1.2;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
        .story__content {
            font-size: rem(14px);
        }
    }
}
.sign-off-photo {
  display: flex;
  margin: 2em 0;

  &__content {
    flex: 1 1 auto;
    padding-left: 1.5em;
  }

  &__sig {
    margin-bottom: 1em;
    max-width: 75%;
  }

  &__role {
    font-family: $font3;
    color:palette(branding, navy);
  }
}
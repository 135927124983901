/* header */.profile-list-header {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.5rem;

    @include mq(m) {
        justify-content: space-between;
    }


    h2 {
        display: none;

        @include mq(m) {
            display: block;
            margin-bottom: 0;
        }
    }


    nav {

    }
}
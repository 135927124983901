/* div */.subnav {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: palette(branding, green);
    color: #FFF;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and ( min-width: 1180px ) {
        padding: 0;
        position: fixed;
        top: 90px;
        transition: top 350ms ease, transform 350ms ease;
        width: 100%;
        z-index: index($layers, subnav);
    }
    @media screen and ( min-height: 600px ) and ( min-width: 1180px ) {
        top: 18vh;

        .scrolled & {
            top: 15vh;
        }
    }

    .header-unpinned & {

        @media screen and ( min-width: 1180px ) {
            transform: translateY(calc((50px + 15vh) * -1));
        }
    }

    .l-container {
        display: flex;
        align-items: center;

        @include mq( $until: site-nav ) {
            justify-content: space-between;
            width: 100%;

            &::after {
                @include size( 0.75rem );
                border-right: 2px solid #FFF;
                border-bottom: 2px solid #FFF;
                color: #FFF;
                content: ' ';
                display: block;
                pointer-events: none;
                position: absolute;
                transform-origin: 50% 50%;
                transform: translateY(-3px) rotate(45deg);
                transition: transform 200ms ease;
                right: 1.5rem;
                top: 37%;

                .site-subnav-is-open & {
                    transform: translateY(3px) rotate(-135deg);
                }
            }
        }
        @include mq(site-nav) {
            justify-content: center;
        }
    }


    &__label {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        @include tracking(50);

        @include mq( $until: site-nav ) {
            cursor: pointer;
            flex: 1 1 auto;

            &::before {
                @include swash();
                content: 'More in ';
                font-family: $font3;
                font-style: italic;
                text-transform: none;
                font-weight: 300;
                color: rgba(#FFF,0.8);
                line-height: 1;
                display: inline-block;
                margin-right: 0.1em;
            }

            strong {
                display: inline-block;
                line-height: 0.8;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 7em);
            }

            .desktop-only {
                display: none;
            }
        }
        @include mq( $until: 399px ) {

            .hide-mobile {
                display: none;
            }
        }
        @include mq(site-nav) {
            @include smoothing(false);
            font-size: rem(16px);
            margin-right: 1rem;

            a {
                border-bottom: 1px solid rgba(#FFF, 0.3);
                color: #FFF;
                transition: border-color 200ms ease;

                &:hover {
                    border-color: #FFF;
                }
            }

            &.mobile-only {
                display: none;
            }
        }
    }


    &__select {
        font-size: rem(14px);

        @include mq(site-nav) {
            display: none;
        }
    }


    &__nav {

        @include mq($until: site-nav) {
            // display: none;
            background-color: darken(palette(branding, pri), 15);
            left: 0;
            opacity: 0;
            padding: 0.5rem 1rem;
            pointer-events: none;
            position: absolute;
            top: 100%;
            transition: opacity 250ms ease;
            width: 100%;
            z-index: index($layers, subnav);

            li {
                margin: 0;

                &:not(:last-child) {
                    border-bottom: 1px solid darken(palette(branding, pri), 7);
                }
            }
            a {
                color: lighten(palette(branding, pri), 20);
                display: block;
                padding: 0.5em 0;

                &::after {
                    color: palette(branding, pri);
                    content: '\00ab';
                    display: inline-block;
                    margin-left: 0.5rem;
                    opacity: 0;
                    position: relative;
                    transform: translateX(10px);
                    transition: opacity 200ms ease, transform 200ms ease;
                }

                &:hover {
                    color: #FFF;

                    &::after {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
                &.is-active {
                    color: #FFF;

                    &::after {
                        color: palette(branding, sec);
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }

            .site-subnav-is-open & {
                opacity: 1;
                pointer-events: auto;
            }
        }

        @include mq(site-nav) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;

            li {
                margin-bottom: 0;
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }

            a {
                font-size: rem(16px);
                padding: 0.35em 0.5em;

                &:link,
                &:visited {
                    color: rgba(#FFF, 0.9);
                    border: 1px solid transparent;
                }

                &:focus,
                &:hover {
                    color: #FFF;
                    border-color: rgba(#FFF, 0.3);
                }

                &.is-active {
                    color: #FFF;
                    border-color: #FFF;
                }
            }
        }
    }
}
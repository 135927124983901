.l-matrix {

    &--3 {
        @include mq(600px) {
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            @include mq(600px) {
                width: 50%;
                padding-right: $gutter;
            }

            @include mq(900px) {
                width: 33.33%;
                padding-right: $gutter;
            }
        }
    }


    &--4 {
        @include mq(600px) {
            display: flex;
            flex-wrap: wrap;
        }

        > * {
            @include mq(600px) {
                width: 50%;
                padding-right: $gutter;
            }

            @include mq(900px) {
                width: 25%;
                padding-right: $gutter;
            }
        }
    }
}
/* ul */.tabbed-content {

    &__item {
        list-style: none;

        @include mq(m) {
            display: inline;
        }
    }


    &__title {
        display: block;
        border: 2px solid palette(ui, grey);
        padding: 0.75rem 0.5rem;
        margin-bottom: 1rem;
        border-radius: 2px;

        @include mq(m) {
            position: relative;
            top: 2px;
            display: inline-block;
            margin-bottom: 0;
            margin-right: 0.5rem;
            padding: 0.5rem;
            min-width: 8em;
            text-align: center;
        }

        &:focus,
        &:hover {
            background-color: lighten(palette(ui, grey), 8);
        }

        &.is-active {
            background-color: palette(ui, grey);
        }


        h3 {
            font-family: $font1;
            text-transform: uppercase;
            color: palette(base, font--l);
            font-size: rem(14px);
            font-weight: 500;
            margin: 0;
            line-height: 1;
        }
    }


    &__content {
        display: none;
        width: 100%;
        padding: 0 0.15rem;

        @include mq(m) {
            float: left;
            border-top: 2px solid palette(ui, grey);
            padding-top: 1rem;
        }

        &.is-open {
            margin-bottom: 2rem;
        }
    }
}
/* ul */.profile-list {
    @include mq(600px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    li {
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;

        @include mq(600px) {
            max-width: none;
            width: calc(50% - 0.5rem);
            margin-left: 0;
            margin-right: 0;

            &:nth-child(2n) {
                // border: 2px solid red;
                // margin-right: 0;
            }
        }

        @include mq(800px) {
            width: calc(33.33% - 0.66rem);
        }

        @include mq(l) {
            width: calc(50% - 0.5rem);
        }

        @include mq(xl) {
            width: calc(33.33% - 0.66rem);
        }
    }
}
.line-title {
    position: relative;
    z-index: 1;

    span {
        position: relative;
        display: inline-block;
        background-color: palette(base, body);
        padding-right: 1em;
        overflow: visible;

        &::after {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -3px;
            margin-right: -2px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background: palette(ui, grey);
        }
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 50%;
        margin-top: 0px;
        height: 1px;
        background: palette(ui, grey);
    }
}
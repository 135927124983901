/* section */.featured-profiles {
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 1rem;
    background-color: palette(branding, green--l);
    -webkit-font-smoothing: antialiased;


    &__title {
        text-align: center;
        margin-bottom: 2rem;
        font-family: $font1;
        color: #fff;
        font-size: rem(24px);
        @include tracking(200);
        text-transform: uppercase;
        font-weight: 700;
        -webkit-font-smoothing: antialiased;
    }


    &__list {
        max-width: $max-width--9;
        margin-left: auto;
        margin-right: auto;

        @include mq(1280px) {
            max-width: none;
            display: flex;
        }
    }



    &__item {
        margin-bottom: 2rem;
        padding-bottom: 1rem;


        @include mq(600px) {
            flex: 1 1 auto;
            padding-top: 0;
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(#fff, 0.5);

            &:first-child {
                border-top: 1px solid rgba(#fff, 0.5);
            }
        }

        @include mq(1280px) {
            padding-right: 2rem;
            border-top: 1px solid rgba(#fff, 0.5);
        }



        a {
            color: #fff;

            @include mq(600px) {
                display: flex;
                align-items: center;
                text-align: left;
            }
        }

        h4 {
            margin-bottom: 0;
            font-size: rem(46px);
            line-height: 0.85;
            text-transform: uppercase;

            @include mq(500px) {
                font-size: rem(66px);
            }

            @include mq(600px) {
                margin-left: 1rem;
            }
        }

        span {
            display: block;
            margin-top: 0.5rem;
            font-size: rem(24px);
            line-height: 1;
            font-family: $font3;
            font-style: italic;
            font-weight: 600;
            text-transform: none;

            @include mq(600px) {
                &::before {
                    content: "–";
                    margin-right: 0.25em;
                }
            }
        }

        img {
            opacity: 1;
            transition: opacity 0.3s ease;
        }


        &:hover {
            img {
                opacity: 0.8;
            }
        }
    }

    .button {
        background-color: palette(branding, green);
        border-radius: 4px;

        &:focus,
        &:hover {
            background-color: palette(branding, salmon);
        }
    }
}

/**
Based on WTF Forms – https://github.com/mdo/wtf-forms
*/

.select {
    position: relative;
    display: inline-block;
    color: palette(base, font);
    width: 100%;
}

.select select {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0.9em 3em 0.9em 0.9em;
    line-height: 1;
    color: palette(base, font);
    background-color: #FFF;
    border: 0;
    line-height: 1.1;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
         -moz-appearance: none;
                    appearance: none;
}
/* Undo the Firefox inner focus ring */
.select select:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}
/* Dropdown arrow */
.select:after {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    margin-top: -.15rem;
    pointer-events: none;
    border-top: .35rem solid;
    border-right: .35rem solid transparent;
    border-bottom: .35rem solid transparent;
    border-left: .35rem solid transparent;
}

.select--salmon .select {
    &::after {
        right: 1rem;
        color: #fff;
        transform: translateX(50%);
        z-index: 2;

        @include mq( 350px ) {
            right: 1.5rem;
        }
    }

    &::before {
       position: absolute;
       top: 0;
       bottom: -1px;
       right: 0;
       display: block;
       content: "";
       background-color: palette(branding, salmon);
       width: 2em;
       pointer-events: none;
       z-index: 1;

       @include mq( 350px ) {
           width: 3em;
       }
    }
}

.select--green--d .select {
    &::after {
        right: 1rem;
        color: #fff;
        transform: translateX(50%);
        z-index: 2;

        @include mq( 350px ) {
            right: 1.5rem;
        }
    }

    &::before {
       position: absolute;
       top: 0;
       bottom: -1px;
       right: 0;
       display: block;
       content: "";
       background-color: palette(branding, green--d);
       width: 2em;
       pointer-events: none;
       z-index: 1;

       @include mq( 350px ) {
           width: 3em;
       }
    }
}


/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.select select:hover {
    background-color: #ddd;
}
*/

/* Focus */
.select select:focus {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

/* Active/open */
.select select:active {
    color: #fff;
    background-color: #0074d9;
}

/* Hide the arrow in IE10 and up */
.select select::-ms-expand {
    display: none;
}

/* Media query to target Firefox only */
@-moz-document url-prefix() {
    /* Firefox hack to hide the arrow */
    .select select {
        text-indent: 0.01px;
        text-overflow: '';
        padding-right: 1rem;
    }

    /* <option> elements inherit styles from <select>, so reset them. */
    .select option {
        background-color: #fff;
    }
}

/* IE9 hack to hide the arrow */
@media screen and (min-width:0\0) {
    .select select {
        z-index: 1;
        padding: .5rem 1.5rem .5rem 1rem;
    }
    .select:after {
        z-index: 5;
    }
    .select:before {
        position: absolute;
        top: 0;
        right: 1rem;
        bottom: 0;
        z-index: 2;
        content: "";
        display: block;
        width: 1.5rem;
        background-color: #FFF;
    }
    .select select:hover,
    .select select:focus,
    .select select:active {
        color: palette(base, font);
        background-color: #FFF;
    }
}

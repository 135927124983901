form {
}

fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

input,
label,
select {
	display:block;
}

label {
	color:palette(branding,navy);
	margin-bottom:.5em;
	font-size: rem(16px);
	text-transform: uppercase;
	font-weight: 700;
	@include tracking(25);

	&.required::after {
		content:"*";
	}

	abbr {
		display:none;
	}

	&.error {
		color:palette(status, error);;
		margin-top:.25em;
	}
}

#{$all-text-inputs},
select,
select[multiple=multiple] {
	appearance:none;
	border-radius:0;
	background-color:#fff;
	border:1px solid #aaa;
	color:#000;
	display:block;
	font-family: inherit;
	font-size:1em;
	padding: 0.9em;
	max-width:100%;
	transition:all .3s ease;
	width:100%;

	&::placeholder {
		color:#ccc;
	}

	&.error {
		border:1px solid palette(status, error) !important;
	}

	&:hover {
		border-color:#000;
	}

	&:focus {
		outline:none;
	}

	&:disabled {
		cursor:not-allowed;
	}
}

textarea {
	resize:vertical;
}

input[type="checkbox"],
input[type="radio"] {
	display:inline;
	margin-right:.5em;

	+ label {
		display:inline-block;
	}
}

input[type="file"] {
	margin-bottom:1em;
	outline:0;
	width:100%;
}

form .button {
	padding-top: 0.9em;
	padding-bottom: 0.9em;
}
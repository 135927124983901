.hero {
    align-items: center;
    background-color: #ccc;
    background-position: 50% bottom;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    justify-content: space-around;
    padding-bottom: 6vh;
    padding-top: 6vh;
    position: relative;

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1.5rem;
        background: linear-gradient(to bottom, rgba(0,0,0,0.07) 0%,rgba(0,0,0,0) 100%);
    }

    .l-container {
        margin: 0;
        width: 100%;

        &::after {
            display: none;
        }
    }

    &__top {
        margin-bottom: 3rem !important;
    }

    &__bottom {

        p {
            color: #fff;
            font-size: 16px;
            max-width: 26em;
            text-shadow: 2px 2px 10px #000;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    h1 {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        span {
            display: block;
            margin-bottom: 1rem;
            font-family: $font3 !important;
            font-style: italic;
            font-weight: 400;
            text-transform: capitalize;
            color: palette(branding, green);
            font-size: rem(30px);
        }

        a {
            text-transform: uppercase;
            font-size: 5.5vmin;
            line-height: 1;
            margin-bottom: 0.15em;

            .wf-nimbussanscondensed-n7-active & {
                font-family: $font2;
                font-size: 10vmin;
            }

            &:link,
            &:visited {
                color: palette(branding, navy);
            }

            &:focus,
            &:hover,
            &.is-active {
                color: palette(branding, pri);
            }
        }
    }
}
.l-container {
    @include grid-container();
	margin-left: auto;
    margin-right: auto;
	max-width: $max-width;
    padding-left: 15px;
    padding-right: 15px;

    @include mq(m) {
        padding-left: 20px;
        padding-right: 20px;
    }


    &--9 {
        position: relative;
        max-width: $max-width--9;
    }

    &--11 {
        max-width: $max-width--11;
    }

    &--13 {
        max-width: $max-width--13;
    }

    &--17 {
        max-width: $max-width--17;
    }
}
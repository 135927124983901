/*------------------------------------*\
$DISPLAY
\*------------------------------------*/

.u-sr-only {
	border:0;
	clip:rect(0,0,0,0);
	height:1px;
	margin:-1px;
	overflow:hidden;
	padding:0;
	position:absolute;
	width:1px;
}

.u-white-space-nowrap {
	white-space:nowrap;
}

.u-hidden {
	display:none!important;
}

.u-display-block {
	display:block!important;
}

.u-display-inline {
	display:inline!important;
}

.u-display-inline-block {
	display:inline-block!important;
}

.u-full-width {
	width: 100%;
}






/*------------------------------------*\
$MARGINS, SPACING, ALIGNMENT
\*------------------------------------*/
.u-flush {
	margin: 0;
}

.u-flush--bottom {
	margin-bottom: 0;
}

.u-push--top {
	margin-top: 1rem;
}

.u-push--top--2 {
	margin-top: 2rem;
}

.u-push--top--4 {
	margin-top: 4rem;
}

.u-push--bottom {
	margin-bottom: 1rem;
}

.u-push--bottom--2 {
	margin-bottom: 2rem;
}

.u-push--bottom--4 {
	margin-bottom: 4rem;
}

.u-push--bottom--6 {
	margin-bottom: 6rem;
}








/*------------------------------------*\
$IMAGES
\*------------------------------------*/

.u-img-responsive {
	display:block;
	height:auto;
	max-width:100%;
}





/*------------------------------------*\
$TEXT
\*------------------------------------*/

.u-swash {
	font-feature-settings: "swsh";
}

.u-text-s {
	font-size: 0.85em;
}






/*------------------------------------*\
$COLORS
\*------------------------------------*/

.u-col-salmon {
	color: palette(branding, salmon);
}

.u-col-green {
	color: palette(branding, green);
}


.u-col-green--l {
	color: palette(branding, green--l);
}

.u-col-navy {
	color: palette(branding, navy);
}
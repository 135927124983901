/* article */.profile {

    &__photos {
        img {
            width: 100%;
        }

        @include mq(m) {
            float: left;
            width: 50%;
            padding-right: 1rem;
        }
    }


    &__meta {
        @include mq(m) {
            float: left;
            width: 50%;
            padding-left: 1rem;
        }
    }


    &__body {
        @include mq(m) {
            clear: both;
            width: 100%;
            padding-top: 2rem;
        }
    }


    &__actions {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        @include mq(500px) {
            position: absolute;
            right: 0;
        }

        @include mq(m) {
            position: static;
            text-align: right;
        }

        .button {
            min-width: 7em;
        }
    }


    &__title {
        margin-top: 0.1em;
        margin-bottom: 0;
        font-family: $font2;
        font-size: rem(36px);
        text-transform: uppercase;
        color: palette(base, font--l);
    }
}
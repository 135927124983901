/* ul */.team-profiles {
    line-height: 1.7;
    margin-bottom: 0;
    padding-bottom: 6rem;

    > li {
        position: relative;

        @include mq(m) {
            padding-left: calc(24% + 2rem);
        }

        &:not(:last-child) {
            margin-bottom: 5rem;
        }
    }

    &__photo {
        border: 1px solid palette(branding, green);
        background-color: palette(ui, grey--xl);
        margin-bottom: 1rem;

        @include mq(500px) {
            float: right;
            margin-left: 2rem;
        }

        @include mq(m) {
            position: absolute;
            left: 0;
            top: 0.5rem;
            width: 24%;
            margin-left: 0;
        }
    }


    &__header {
        line-height: 1.2;
        margin-bottom: 1rem;

        strong {
            font-family: $font1;
            font-weight: 500;
            font-size: rem(24px);
            color: palette(branding, salmon);
            font-style: normal;
        }

        em {
            color: palette(branding, navy);
            font-family: $font3;
            font-size: rem(23px);
            font-styke: italic;
            font-weight: 400;

            @include mq( $until: 499px ) {
                display: inline-block;
            }
            @include mq( 740px ) {
                display: inline-block;
            }
        }
    }

    p {
        font-size: 16px;
    }
}
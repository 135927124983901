#__bs_notify__ {
	background:#000!important;
	border:none!important;
	border-radius:0!important;
	color:#fff!important;
	font:small-caption!important;
	padding:3px 6px!important;
	pointer-events:none;
	position:fixed;
	right:0;
	opacity:.5;
	z-index:100;

	// Toggle the below comments if the notification is getting in the way of where you're working

	bottom:19px!important;
	top:auto!important;

	// left:0!important;
	// right:auto!important;
}
.title-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 0;
    border: 1px solid palette(branding, salmon);


    &__title {
        color: palette(branding, green--l);
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding: 0 1rem;
    }

    &__content {
        flex-shrink: 0;
        margin-bottom: 1rem;
        margin-top: 1rem;
        padding: 0 1rem;
    }


    &__list {
        margin-bottom: 1rem;
        color: palette(branding, navy);

        a {
            border-bottom: 1px solid #D0D0D0;
            color: palette(base, font);
            transition: all 200ms ease;

            &:hover {
                border-color: palette(branding, pri);
                color: palette(branding, pri);
            }
        }
    }


    &__link {
        font-size: rem(12px);
        text-transform: uppercase;
        border-bottom: 1px dotted;
        font-weight: 500;
        @include tracking(200);

        &:link,
        &:visited {
            color: palette(branding, salmon);
        }

        &:focus,
        &:hover {
            color: palette(branding, green);
        }
    }
}
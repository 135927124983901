.lede {
    margin-top: 2rem;
    font-family: $font3;
    font-style: italic;
    font-size: rem(28px);
    line-height: 1.4;
    color: palette(branding, navy);

    &:first-child {
        margin-top: 0;
    }

    em {
      color: palette(branding, green);
    }

    a {
        border: none !important;
    }
}
img,
picture,
figure {
	margin:0;
	max-width:100%;
}

img[src$=".svg"] {
    display: block;
    width: 100%;
    height: auto;
}
